.datenschutz-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.datenschutz-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.datenschutz-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.datenschutz-container1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.datenschutz-navlink {
  display: contents;
}
.datenschutz-image {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.datenschutz-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.datenschutz-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.datenschutz-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.datenschutz-link {
  text-decoration: none;
}
.datenschutz-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.datenschutz-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.datenschutz-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.datenschutz-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.datenschutz-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.datenschutz-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.datenschutz-text {
  color: var(--dl-color-gray-white);
}
.datenschutz-burger-menu {
  display: none;
}
.datenschutz-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.datenschutz-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.datenschutz-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.datenschutz-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.datenschutz-logo {
  height: 2rem;
}
.datenschutz-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.datenschutz-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.datenschutz-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.datenschutz-text003 {
  margin-bottom: var(--dl-space-space-unit);
}
.datenschutz-text004 {
  margin-bottom: var(--dl-space-space-unit);
}
.datenschutz-text005 {
  margin-bottom: var(--dl-space-space-unit);
}
.datenschutz-text006 {
  margin-bottom: var(--dl-space-space-unit);
}
.datenschutz-text007 {
  margin-bottom: var(--dl-space-space-unit);
}
.datenschutz-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.datenschutz-login {
  margin-right: var(--dl-space-space-twounits);
}
.datenschutz-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.datenschutz-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.datenschutz-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.datenschutz-container3 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 112px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.datenschutz-container4 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.datenschutz-text008 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-twounits);
}
.datenschutz-text010 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text011 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text013 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text014 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text023 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text026 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text027 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text033 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text034 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text044 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text045 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text051 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text052 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text066 {
  font-size: 20px;
  font-weight: 600;
}
.datenschutz-text067 {
  font-size: 20px;
  font-weight: 600;
}
.datenschutz-text077 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text078 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text083 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text084 {
  font-size: 20px;
}
.datenschutz-link6 {
  text-decoration: underline;
}
.datenschutz-text096 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text102 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text105 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text124 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text130 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text131 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text138 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text150 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text161 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text162 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text163 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text180 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text181 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text194 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text195 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text202 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text203 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text204 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text226 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text227 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text235 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text236 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text244 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text245 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text253 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text254 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-ul {
  list-style-type: disc;
}
.datenschutz-text268 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text277 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text278 {
  font-size: 32px;
}
.datenschutz-text280 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text301 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text313 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text325 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.datenschutz-text326 {
  font-size: 32px;
}
.datenschutz-ul1 {
  list-style-type: disc;
}
.datenschutz-text343 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text344 {
  font-weight: 400;
}
.datenschutz-text347 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text350 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text354 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text356 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.datenschutz-ul2 {
  list-style-type: disc;
}
.datenschutz-text358 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text360 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text362 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text364 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text366 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-text368 {
  font-style: normal;
  font-weight: 600;
}
.datenschutz-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.datenschutz-image1 {
  height: 2rem;
  object-fit: cover;
}
.datenschutz-navlink1 {
  text-decoration: none;
}
.datenschutz-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.datenschutz-link7 {
  display: contents;
}
.datenschutz-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media (max-width: 767px) {
  .datenschutz-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .datenschutz-desktop-menu {
    display: none;
  }
  .datenschutz-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .datenschutz-text003 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .datenschutz-text004 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .datenschutz-text005 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .datenschutz-text006 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .datenschutz-text007 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .datenschutz-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media (max-width: 479px) {
  .datenschutz-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .datenschutz-mobile-menu {
    padding: 16px;
  }
  .datenschutz-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .datenschutz-image1 {
    margin-bottom: var(--dl-space-space-unit);
  }
}

.datenschutz-container4 {
  padding: 2rem;
}

@media screen and (max-width: 470px) {
  span {
    line-break: auto;
  }

  .datenschutz-link6 {
    line-break: anywhere;
  }

  .datenschutz-text008 {
    font-size: 28px;
  }
}
