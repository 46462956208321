.pflege-von-grnanlagen-container3 {
  padding: 2rem;
  height: 100%;
}

.pflege-von-grnanlagen-container5 {
  width: auto;
}

.pflege-von-grnanlagen-container6 {
  padding: 2rem;
  gap: 24px;
}

.pflege-von-grnanlagen-text16 {
  align-self: center;
}

.pflege-von-grnanlagen-container1 {
  height: 98px;
}

@media screen and (max-width: 900px) {
  .pflege-von-grnanlagen-container3 {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .pflege-von-grnanlagen-container3 > div {
    width: 100%;
  }

  .pflege-von-grnanlagen-container5 {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .pflege-von-grnanlagen-image {
    width: 100%;
    height: 100%;
  }

  .pflege-von-grnanlagen-slider {
    width: 100%;
  }

  .pflege-von-grnanlagen-container4 {
    height: 100%;
  }
}
