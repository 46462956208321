.gebudereinigung-gebudereinigung.gebudereinigung-gebudereinigung {
  padding: 2rem;
}

.gebudereinigung-container2 {
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.gebudereinigung-image {
  width: 500px;
}

.gebudereinigung-ul {
  padding: 24px;
  border: none;
  border-radius: 12px;
  background-color: var(--dl-color-custom-secondary1);
}

.gebudereinigung-grundreinigung {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

.gebudereinigung-slider {
  width: 50%;
  max-height: 500px;
  height: 300px;
}

.gebudereinigung-glasreinigung {
  padding: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.gebudereinigung-slider1 {
  width: 100%;
}

.gebudereinigung-teppich-und-polster-reinigung {
  flex-direction: row-reverse;
  padding: 2rem;
  align-items: center;
}

.gebudereinigung-image1 {
  width: 100%;
  height: 100%;
}

.gebudereinigung-solaranlagenreinigung {
  flex-direction: row;
  padding: 2rem;
  align-items: center;
}

.gebudereinigung-slider2 {
  width: 50%;
  height: 100%;
}

.gebudereinigung-glasreinigung .swiper-wrapper > div {
  background-size: 100%;
  width: 200px;
}

.swiper-wrapper > div {
  width: 100%;
  background-size: cover;
}

.gebudereinigungSlides {
  background-size: cover;
  background-repeat: no-repeat;
}

.gebudereinigung-container1 {
  height: 98px;
}

@media screen and (max-width: 1280px) {
  .gebudereinigung-container2 {
    flex-direction: column-reverse;
  }

  .gebudereinigung-container3 {
    width: 100%;
  }

  .gebudereinigung-slider,
  .gebudereinigung-slider1,
  .gebudereinigung-image1,
  .gebudereinigung-slider2 {
    width: 40%;
  }

  .flex-container {
    width: 60%;
  }

  .gebudereinigung-glasreinigung .swiper-wrapper > div {
    background-size: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 989px) {
  .gebudereinigung-grundreinigung,
  .gebudereinigung-glasreinigung,
  .gebudereinigung-teppich-und-polster-reinigung,
  .gebudereinigung-solaranlagenreinigung {
    flex-direction: column;
    gap: 24px;
  }

  .gebudereinigung-slider {
    height: 400px;
  }

  .gebudereinigung-grundreinigung > div,
  .gebudereinigung-slider1,
  .flex-container {
    width: 100%;
  }
  .gebudereinigung-slider.swiper,
  .gebudereinigung-slider2 {
    width: 50%;
  }

  .gebudereinigung-glasreinigung .swiper-wrapper > div {
    background-size: 100%;
    width: 200px;
  }

  .gebudereinigung-glasreinigung .swiper-wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .flex-container {
    padding: 0;
  }

  .gebudereinigung-image,
  .gebudereinigung-container4,
  .gebudereinigung-image1,
  .gebudereinigung-glasreinigung .swiper-wrapper > div {
    width: 100%;
  }

  .gebudereinigung-glasreinigung .swiper-wrapper {
    justify-content: unset;
  }

  .swiper {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .gebudereinigung-text,
  .gebudereinigung-text080 {
    font-size: 28px;
  }
}
