.gebudereinigung-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.gebudereinigung-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 112px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gebudereinigung-gebudereinigung {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-custom-accent1);
}
.gebudereinigung-text {
  color: var(--dl-color-gray-black);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-container2 {
  width: 100%;
  height: 259px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.gebudereinigung-container3 {
  flex: 0 0 auto;
  width: 625px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gebudereinigung-container4 {
  width: 625px;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gebudereinigung-text001 {
  width: 100%;
  height: 100%;
  font-size: 18px;
}
.gebudereinigung-text003 {
  font-size: 18px;
}
.gebudereinigung-text005 {
  font-size: 18px;
}
.gebudereinigung-ul {
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  list-style-type: disc;
  list-style-position: outside;
}
.gebudereinigung-link {
  text-decoration: none;
}
.gebudereinigung-link01 {
  text-decoration: none;
}
.gebudereinigung-link02 {
  text-decoration: none;
}
.gebudereinigung-link03 {
  text-decoration: none;
}
.gebudereinigung-container5 {
  width: 337px;
  height: 238px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gebudereinigung-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-left: var(--dl-space-space-unit);
  padding-bottom: (--dl-space-space-halfunit);
}
.gebudereinigung-grundreinigung {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.gebudereinigung-text011 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-slider {
  width: 500px;
  height: 300px;
  display: inline-block;
}
.gebudereinigung-slider-slide {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Grundreinigung/1590741240%20(1)-500w.jpg");
}
.gebudereinigung-text012 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide01 {
  color: var(--dl-color-custom-neutral-light);
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("/Gebäudereinigung/Grundreinigung/1590741240-300h.jpg");
}
.gebudereinigung-text013 {
  width: 100%;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide02 {
  display: flex ;
  position: relative;
  background-size: 100%;
  background-image: url("/Gebäudereinigung/Grundreinigung/1590743011-500w.jpg");
}
.gebudereinigung-text014 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  height: auto;
  align-self: center;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-text017 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-pagination {
  display: block;
}
.gebudereinigung-text037 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.gebudereinigung-ul1 {
  list-style-type: disc;
}
.gebudereinigung-glasreinigung {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-accent1);
}
.gebudereinigung-text043 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-slider1 {
  width: 500px;
  height: 300px;
  display: inline-block;
}
.gebudereinigung-slider-slide04 {
  width: 200px;
  height: 318px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Glasreinigung/1591344476-400h.jpg");
}
.gebudereinigung-text044 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide05 {
  width: 175px;
  height: 301px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Glasreinigung/1591344412-400h.jpg");
}
.gebudereinigung-text045 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide06 {
  width: 192px;
  height: 300px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Glasreinigung/1591344412%20(1)-200w.jpg");
}
.gebudereinigung-text048 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-pagination1 {
  display: block;
}
.gebudereinigung-teppich-und-polster-reinigung {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.gebudereinigung-text068 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-image1 {
  width: 265px;
  height: 273px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-bottom: 0px;
}
.gebudereinigung-text069 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-solaranlagenreinigung {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-accent1);
}
.gebudereinigung-text080 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-slider2 {
  width: 500px;
  height: 296px;
  display: inline-block;
}
.gebudereinigung-slider-slide07 {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Solaranlagenreinigung/1591344542-300h.jpg");
}
.gebudereinigung-text081 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide08 {
  width: 490px;
  height: 294px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Solaranlagenreinigung/1591344542%20(1)-300h.jpg");
}
.gebudereinigung-text082 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-slide09 {
  width: 224px;
  height: 299px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-size: 100%;
  justify-content: center;
  background-image: url("/Gebäudereinigung/Solaranlagenreinigung/15913444761-300w.jpg");
}
.gebudereinigung-text083 {
  color: var(--dl-color-custom-neutral-light);
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.gebudereinigung-slider-pagination2 {
  display: block;
}
.gebudereinigung-text084 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.gebudereinigung-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.gebudereinigung-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.gebudereinigung-container6 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.gebudereinigung-navlink {
  display: contents;
}
.gebudereinigung-image2 {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.gebudereinigung-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.gebudereinigung-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.gebudereinigung-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.gebudereinigung-navlink1 {
  text-decoration: none;
}
.gebudereinigung-link04 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.gebudereinigung-link05 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.gebudereinigung-link06 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.gebudereinigung-link07 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.gebudereinigung-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.gebudereinigung-link08 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.gebudereinigung-text091 {
  color: var(--dl-color-gray-white);
}
.gebudereinigung-burger-menu {
  display: none;
}
.gebudereinigung-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.gebudereinigung-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.gebudereinigung-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gebudereinigung-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.gebudereinigung-logo {
  height: 2rem;
}
.gebudereinigung-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gebudereinigung-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.gebudereinigung-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.gebudereinigung-text094 {
  margin-bottom: var(--dl-space-space-unit);
}
.gebudereinigung-text095 {
  margin-bottom: var(--dl-space-space-unit);
}
.gebudereinigung-text096 {
  margin-bottom: var(--dl-space-space-unit);
}
.gebudereinigung-text097 {
  margin-bottom: var(--dl-space-space-unit);
}
.gebudereinigung-text098 {
  margin-bottom: var(--dl-space-space-unit);
}
.gebudereinigung-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.gebudereinigung-login {
  margin-right: var(--dl-space-space-twounits);
}
.gebudereinigung-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.gebudereinigung-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.gebudereinigung-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.gebudereinigung-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.gebudereinigung-image3 {
  height: 2rem;
  object-fit: cover;
}
.gebudereinigung-navlink2 {
  text-decoration: none;
}
.gebudereinigung-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.gebudereinigung-link09 {
  display: contents;
}
.gebudereinigung-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .gebudereinigung-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .gebudereinigung-desktop-menu {
    display: none;
  }
  .gebudereinigung-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gebudereinigung-text094 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .gebudereinigung-text095 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .gebudereinigung-text096 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .gebudereinigung-text097 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .gebudereinigung-text098 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .gebudereinigung-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .gebudereinigung-slider-slide {
    height: auto;
  }
  .gebudereinigung-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .gebudereinigung-mobile-menu {
    padding: 16px;
  }
  .gebudereinigung-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .gebudereinigung-image3 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
