.flex-container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 100%;
}

.winterdienst-container2 {
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
}

.winterdienst-container4 {
  width: auto;
}

.winterdienst-image {
  width: 100%;
}

.winterdienst-container2 {
  justify-content: center;
  align-items: center;
}

.winterdienst-container5 {
  gap: 24px;
}

.winterdienst-container1 {
  height: 98px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
}

@media screen and (max-width: 768px) {
  .winterdienst-container2 {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .winterdienst-container3 {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .winterdienst-slider {
    width: 100%;
  }
}
