.impressum-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.impressum-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.impressum-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.impressum-container1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.impressum-navlink {
  display: contents;
}
.impressum-image {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.impressum-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.impressum-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.impressum-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.impressum-link {
  text-decoration: none;
}
.impressum-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.impressum-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.impressum-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.impressum-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.impressum-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.impressum-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.impressum-text {
  color: var(--dl-color-gray-white);
}
.impressum-burger-menu {
  display: none;
}
.impressum-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.impressum-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.impressum-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.impressum-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.impressum-logo {
  height: 2rem;
}
.impressum-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.impressum-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.impressum-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.impressum-text03 {
  margin-bottom: var(--dl-space-space-unit);
}
.impressum-text04 {
  margin-bottom: var(--dl-space-space-unit);
}
.impressum-text05 {
  margin-bottom: var(--dl-space-space-unit);
}
.impressum-text06 {
  margin-bottom: var(--dl-space-space-unit);
}
.impressum-text07 {
  margin-bottom: var(--dl-space-space-unit);
}
.impressum-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.impressum-login {
  margin-right: var(--dl-space-space-twounits);
}
.impressum-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.impressum-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.impressum-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.impressum-container3 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 112px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.impressum-container4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.impressum-text08 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.impressum-text09 {
  padding-bottom: var(--dl-space-space-unit);
}
.impressum-text10 {
  font-style: normal;
  font-weight: 600;
}
.impressum-text11 {
  font-style: normal;
  font-weight: 600;
}
.impressum-text19 {
  font-style: normal;
  font-weight: 600;
}
.impressum-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.impressum-image1 {
  height: 2rem;
  object-fit: cover;
}
.impressum-navlink1 {
  text-decoration: none;
}
.impressum-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.impressum-link6 {
  display: contents;
}
.impressum-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media (max-width: 767px) {
  .impressum-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .impressum-desktop-menu {
    display: none;
  }
  .impressum-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .impressum-text03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-text04 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-text05 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-text06 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-text07 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .impressum-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media (max-width: 479px) {
  .impressum-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .impressum-mobile-menu {
    padding: 16px;
  }
  .impressum-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .impressum-image1 {
    margin-bottom: var(--dl-space-space-unit);
  }
}

.impressum-container4 {
  padding: 2rem;
}
