.grabpflege-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.grabpflege-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 111px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.grabpflege-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-accent1);
}
.grabpflege-text {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.grabpflege-image {
  width: 314px;
  object-fit: cover;
  padding-bottom: var(--dl-space-space-halfunit);
}
.grabpflege-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-custom-secondary1);
}
.grabpflege-text01 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.grabpflege-text02 {
  font-style: normal;
  font-weight: 600;
}
.grabpflege-ul {
  list-style-type: disc;
  list-style-position: outside;
}
.grabpflege-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.grabpflege-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.grabpflege-container4 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.grabpflege-navlink {
  display: contents;
}
.grabpflege-image2 {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.grabpflege-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.grabpflege-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.grabpflege-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.grabpflege-link {
  text-decoration: none;
}
.grabpflege-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.grabpflege-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.grabpflege-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.grabpflege-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.grabpflege-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.grabpflege-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.grabpflege-text20 {
  color: var(--dl-color-gray-white);
}
.grabpflege-burger-menu {
  display: none;
}
.grabpflege-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.grabpflege-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.grabpflege-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.grabpflege-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.grabpflege-logo {
  height: 2rem;
}
.grabpflege-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.grabpflege-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.grabpflege-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.grabpflege-text23 {
  margin-bottom: var(--dl-space-space-unit);
}
.grabpflege-text24 {
  margin-bottom: var(--dl-space-space-unit);
}
.grabpflege-text25 {
  margin-bottom: var(--dl-space-space-unit);
}
.grabpflege-text26 {
  margin-bottom: var(--dl-space-space-unit);
}
.grabpflege-text27 {
  margin-bottom: var(--dl-space-space-unit);
}
.grabpflege-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.grabpflege-login {
  margin-right: var(--dl-space-space-twounits);
}
.grabpflege-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.grabpflege-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.grabpflege-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.grabpflege-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.grabpflege-image3 {
  height: 2rem;
  object-fit: cover;
}
.grabpflege-navlink1 {
  text-decoration: none;
}
.grabpflege-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.grabpflege-link6 {
  display: contents;
}
.grabpflege-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .grabpflege-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .grabpflege-desktop-menu {
    display: none;
  }
  .grabpflege-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .grabpflege-text23 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .grabpflege-text24 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .grabpflege-text25 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .grabpflege-text26 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .grabpflege-text27 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .grabpflege-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .grabpflege-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .grabpflege-mobile-menu {
    padding: 16px;
  }
  .grabpflege-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .grabpflege-image3 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
