.grabpflege-container2 {
  padding: 2rem;
  align-items: center;
  gap: 24px;
}

.grabpflege-container3 {
  padding: 2rem;
}

.grabpflege-container1 {
  height: 98px;
}
