.pflege-von-grnanlagen-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pflege-von-grnanlagen-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 112px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pflege-von-grnanlagen-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pflege-von-grnanlagen-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: var(--dl-color-custom-accent1);
}
.pflege-von-grnanlagen-container4 {
  width: 50%;
  height: 249px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pflege-von-grnanlagen-text {
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.pflege-von-grnanlagen-ul {
  list-style-type: disc;
}
.pflege-von-grnanlagen-container5 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pflege-von-grnanlagen-image {
  width: 348px;
  height: 233px;
  object-fit: cover;
}
.pflege-von-grnanlagen-container6 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-custom-secondary1);
}
.pflege-von-grnanlagen-text16 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.pflege-von-grnanlagen-slider {
  width: 500px;
  height: 300px;
  display: inline-block;
}
.pflege-von-grnanlagen-slider-slide {
  display: flex;
  background-size: 100%;
  background-image: url('/Grünanlagen/1590739032 (1).jpg');
  align-items: center;
  background-size: cover;
  justify-content: center;
}
.grünanlagen-text011 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.pflege-von-grnanlagen-slider-slide1 {
  display: flex;
  background-size: 100%;
  background-image: url('/Grünanlagen/1590739032.jpg');
  align-items: center;
  background-size: cover;
  justify-content: center;
}
.grünanlagen-text012 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.pflege-von-grnanlagen-slider-slide2 {
  display: flex;
  background-size: 100%;
  background-image: url('/Grünanlagen/image (1).jpg');
  align-items: center;
  background-size: cover;
  justify-content: center;
}
.grünanlagen-text013 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.pflege-von-grnanlagen-slider-slide3 {
  display: flex ;
  background-size: 100%;
  background-image: url('/Grünanlagen/image.jpg');
  align-items: center;
  background-size: cover;
  justify-content: center;
}
.grünanlagen-text014 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.pflege-von-grnanlagen-slider-pagination {
  display: block;
}
.pflege-von-grnanlagen-text19 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.pflege-von-grnanlagen-ul1 {
  list-style-type: disc;
}
.pflege-von-grnanlagen-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.pflege-von-grnanlagen-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pflege-von-grnanlagen-container7 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.pflege-von-grnanlagen-navlink {
  display: contents;
}
.pflege-von-grnanlagen-image1 {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.pflege-von-grnanlagen-container8 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.pflege-von-grnanlagen-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.pflege-von-grnanlagen-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.pflege-von-grnanlagen-link {
  text-decoration: none;
}
.pflege-von-grnanlagen-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.pflege-von-grnanlagen-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.pflege-von-grnanlagen-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.pflege-von-grnanlagen-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.pflege-von-grnanlagen-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pflege-von-grnanlagen-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.pflege-von-grnanlagen-text50 {
  color: var(--dl-color-gray-white);
}
.pflege-von-grnanlagen-burger-menu {
  display: none;
}
.pflege-von-grnanlagen-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.pflege-von-grnanlagen-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.pflege-von-grnanlagen-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pflege-von-grnanlagen-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.pflege-von-grnanlagen-logo {
  height: 2rem;
}
.pflege-von-grnanlagen-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pflege-von-grnanlagen-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.pflege-von-grnanlagen-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.pflege-von-grnanlagen-text53 {
  margin-bottom: var(--dl-space-space-unit);
}
.pflege-von-grnanlagen-text54 {
  margin-bottom: var(--dl-space-space-unit);
}
.pflege-von-grnanlagen-text55 {
  margin-bottom: var(--dl-space-space-unit);
}
.pflege-von-grnanlagen-text56 {
  margin-bottom: var(--dl-space-space-unit);
}
.pflege-von-grnanlagen-text57 {
  margin-bottom: var(--dl-space-space-unit);
}
.pflege-von-grnanlagen-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pflege-von-grnanlagen-login {
  margin-right: var(--dl-space-space-twounits);
}
.pflege-von-grnanlagen-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.pflege-von-grnanlagen-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.pflege-von-grnanlagen-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.pflege-von-grnanlagen-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pflege-von-grnanlagen-image2 {
  height: 2rem;
  object-fit: cover;
}
.pflege-von-grnanlagen-navlink1 {
  text-decoration: none;
}
.pflege-von-grnanlagen-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pflege-von-grnanlagen-link6 {
  display: contents;
}
.pflege-von-grnanlagen-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .pflege-von-grnanlagen-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .pflege-von-grnanlagen-desktop-menu {
    display: none;
  }
  .pflege-von-grnanlagen-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pflege-von-grnanlagen-text53 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-text54 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-text55 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-text56 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-text57 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .pflege-von-grnanlagen-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .pflege-von-grnanlagen-mobile-menu {
    padding: 16px;
  }
  .pflege-von-grnanlagen-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pflege-von-grnanlagen-image2 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
