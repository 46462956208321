.home-top {
  margin: 0;
}

.home-nav {
  align-items: center;
  gap: 50px;
  height: 100%;
}

.home-links1 {
  align-self: center;
  align-items: center;
  gap: 30px;
}

.home-links1 > a {
  margin: 0;
}

/* destkop nav  */

.home-navbar-interactive {
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .home-text {
    padding: 0;
  }

  .home-navbar-interactive {
    padding: 0;
    height: 96px;
  }

  .home-container01 {
    width: 96px;
    height: 96px;
  }

  .home-container02 {
    margin-right: 10px;
  }

  .home-image {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
  }

  .home-links {
    gap: 18px;
  }

  .home-links a {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .home-navbar-interactive {
    padding-right: 32px;
  }

  .nav-socials {
    margin-bottom: 32px;
  }

  .home-link5 {
    margin: 0;
  }

  .home-logo {
    width: 70px;
    height: 70px;
  }

  .home-navbar-interactive {
    height: 100%;
  }

  .home-header {
    height: 96px;
  }
}

@media screen and (max-width: 600px) {
  .home-logo {
    width: 48px;
    height: 48px;
  }

  .home-icon10 {
    margin: 0;
  }
}

@media screen and (max-width: 479px) {
  .home-icon10 {
    margin: 0;
  }
}

@media screen and (max-width: 350px) {
  .home-navbar-interactive {
    padding: 0;
    padding-right: 12px;
    justify-content: space-between;
  }

  .home-container-01 {
    width: 60px;
    height: 60px;
  }

  .home-text {
    padding: 0;
  }
}
