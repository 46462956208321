.winterdienst-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.winterdienst-container1 {
  width: 100%;
  height: 121px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
}
.winterdienst-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-custom-accent1);
}
.winterdienst-container3 {
  width: 671px;
  height: 144px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.winterdienst-text {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.winterdienst-container4 {
  flex: 0 0 auto;
  width: 562px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.winterdienst-image {
  width: 200px;
  object-fit: cover;
  padding-top: var(--dl-space-space-oneandhalfunits);
}
.winterdienst-container5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-custom-accent1);
}
.winterdienst-slider {
  width: 500px;
  height: 300px;
  display: inline-block;
}
.winterdienst-slider-slide {
  display: flex;
  background-size: 110%;
  background-image: url('/image.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
}
.winterdienst-text011 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.winterdienst-slider-slide1 {
  display: flex;
  background-size: 100%;
  background-image: url('/1592731970.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
}
.winterdienst-text012 {
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  text-align: center;
  background-color: var(--dl-color-gray-black);
}
.winterdienst-slider-pagination {
  display: block;
}
.winterdienst-text27 {
  font-style: normal;
  font-weight: 700;
}
.winterdienst-ul {
  list-style-type: disc;
}
.winterdienst-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.winterdienst-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.winterdienst-container6 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.winterdienst-navlink {
  display: contents;
}
.winterdienst-image1 {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.winterdienst-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.winterdienst-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.winterdienst-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.winterdienst-link {
  text-decoration: none;
}
.winterdienst-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.winterdienst-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.winterdienst-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.winterdienst-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.winterdienst-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.winterdienst-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.winterdienst-text35 {
  color: var(--dl-color-gray-white);
}
.winterdienst-burger-menu {
  display: none;
}
.winterdienst-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.winterdienst-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.winterdienst-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.winterdienst-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.winterdienst-logo {
  height: 2rem;
}
.winterdienst-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.winterdienst-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.winterdienst-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.winterdienst-text38 {
  margin-bottom: var(--dl-space-space-unit);
}
.winterdienst-text39 {
  margin-bottom: var(--dl-space-space-unit);
}
.winterdienst-text40 {
  margin-bottom: var(--dl-space-space-unit);
}
.winterdienst-text41 {
  margin-bottom: var(--dl-space-space-unit);
}
.winterdienst-text42 {
  margin-bottom: var(--dl-space-space-unit);
}
.winterdienst-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.winterdienst-login {
  margin-right: var(--dl-space-space-twounits);
}
.winterdienst-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.winterdienst-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.winterdienst-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.winterdienst-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.winterdienst-image2 {
  height: 2rem;
  object-fit: cover;
}
.winterdienst-navlink1 {
  text-decoration: none;
}
.winterdienst-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.winterdienst-link6 {
  display: contents;
}
.winterdienst-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media(max-width: 767px) {
  .winterdienst-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .winterdienst-desktop-menu {
    display: none;
  }
  .winterdienst-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .winterdienst-text38 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .winterdienst-text39 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .winterdienst-text40 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .winterdienst-text41 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .winterdienst-text42 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .winterdienst-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .winterdienst-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .winterdienst-mobile-menu {
    padding: 16px;
  }
  .winterdienst-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .winterdienst-image2 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
