.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-navbar-interactive {
  width: 1200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container01 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.home-navlink {
  display: contents;
}
.home-image {
  width: 107px;
  height: 86px;
  object-fit: cover;
  text-decoration: none;
}
.home-text {
  font-style: normal;
  font-weight: 700;
  padding-left: 0px;
  padding-right: var(--dl-space-space-threeunits);
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-link {
  text-decoration: none;
}
.home-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-buttons {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link5 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  background-color: var(--dl-color-custom-primary1);
}
.home-text001 {
  color: var(--dl-color-gray-white);
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  /* display: none; */
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  height: 2rem;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-text003 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text004 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text005 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text006 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text007 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-home {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.home-video {
  width: 100%;
  height: 100%;
}
.home-hero {
  height: 332px;
  background-size: cover;
  background-color: transparent;
  background-image: url("/image-1500w.jpg");
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: 173px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container04 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 988px;
  height: 106px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  width: 829px;
  height: 102px;
  font-size: 4em;
  max-width: 800px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 900;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text022 {
  font-size: 22px;
  text-align: center;
  border-color: var(--dl-color-primary-700);
  border-style: solid;
  border-width: 5px;
  background-color: var(--dl-color-gray-900);
}
.home-container05 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-custom-neutral-light);
}
.home-hero-button1 {
  text-decoration: none;
}
.home-dienstleistungen {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features-container {
  align-items: center;
  justify-content: center;
}
.home-dienstleistungen1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container06 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container07 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-feature-card1 {
  text-decoration: none;
}
.home-feature-card2 {
  text-decoration: none;
}
.home-feature-card3 {
  text-decoration: none;
}
.home-feature-card4 {
  text-decoration: none;
}
.home-feature-card41 {
  text-decoration: none;
}
.home-referenzen {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-text040 {
  font-size: 3rem;
  text-align: center;
}
.home-text041 {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-container08 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-image01 {
  width: 200px;
  object-fit: cover;
}
.home-image02 {
  width: 200px;
  object-fit: cover;
}
.home-image03 {
  width: 200px;
  object-fit: cover;
}
.home-image04 {
  width: 200px;
  object-fit: cover;
}
.home-image05 {
  width: 256px;
  height: 103px;
  object-fit: cover;
}
.home-image06 {
  width: 200px;
  object-fit: cover;
}
.home-image07 {
  width: 200px;
  object-fit: cover;
}
.home-image08 {
  width: 200px;
  object-fit: cover;
}
.home-container27 {
  flex: 0 0 auto;
  width: auto;
  height: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container28 {
  flex: 0 0 auto;
  width: auto;
  height: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container29 {
  flex: 0 0 auto;
  width: auto;
  height: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-image09 {
  width: 388px;
  height: 74px;
  object-fit: cover;
}
.home-image20 {
  width: 100%;
  height: 74px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fiveunits);
}
.home-ueber-uns {
  width: 100%;
  height: 620px;
  display: flex;
  align-items: center;
  border-color: rgba(108, 179, 128, 0.75);
  border-width: 50px;
  flex-direction: column;
}
.home-container110 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container111 {
  flex: 0 0 auto;
  width: 100%;
  height: 266px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image10 {
  height: 256px;
  object-fit: cover;
}
.home-container112 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 433px;
  height: auto;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-text128 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-text129 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-image11 {
  top: 0px;
  right: 0px;
  width: 256px;
  position: absolute;
  object-fit: cover;
}
.home-container113 {
  width: 100%;
  height: 586px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container114 {
  width: 100%;
  height: 47px;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-text133 {
  width: 100%;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  padding-bottom: var(--dl-space-space-unit);
}
.home-container115 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container116 {
  flex: 0 0 auto;
  width: 33%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-text134 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.home-text135 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-container117 {
  flex: 0 0 auto;
  width: 33%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-text136 {
  width: 100%;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text137 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-ul {
  list-style-type: disc;
}
.home-container118 {
  flex: 0 0 auto;
  width: 33%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.home-text144 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.home-text145 {
  padding-top: var(--dl-space-space-halfunit);
}
.home-kontakt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-custom-accent1);
}
.home-container18 {
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container19 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container20 {
  width: 100%;
  height: 50%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-text073 {
  color: var(--dl-color-gray-black);
  width: 100%;
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0px;
}
.home-text074 {
  color: var(--dl-color-gray-black);
  width: 100%;
  text-align: center;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text075 {
  color: var(--dl-color-success-300);
  font-size: 24px;
  align-self: flex-start;
}
.home-link6 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-text080 {
  color: var(--dl-color-success-300);
  font-size: 24px;
  align-self: flex-start;
}
.home-text081 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text084 {
  color: var(--dl-color-success-300);
  font-size: 24px;
  align-self: flex-start;
}
.home-text085 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
}
.home-container21 {
  width: 100%;
  height: 460px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-container22 {
  width: 100%;
  height: 100%;
}
.home-container23 {
  display: contents;
}
.MapsFrame {
  width: 100%;
  height: 100%;
}
.home-container24 {
  width: 50%;
  height: 766px;
  display: flex;
  align-items: flex-start;
}
.home-form {
  width: 100%;
  height: 718px;
  margin: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-style: groove;
  border-width: 5px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: linear-gradient(to right, #bdc3c7 0%, #2c3e50 100%);
}
.home-text088 {
  color: var(--dl-color-gray-black);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text089 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  align-self: flex-start;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-textinput {
  color: var(--dl-color-gray-black);
  width: 465px;
  height: 45px;
  align-self: flex-start;
  text-align: left;
  border-width: 0px;
  background-color: var(--dl-color-primary-700);
}
.home-text090 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  align-self: flex-start;
  text-align: left;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-textinput1 {
  color: var(--dl-color-gray-black);
  width: 465px;
  height: 45px;
  align-self: flex-start;
  border-width: 0px;
  background-color: var(--dl-color-primary-700);
}
.home-text091 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  align-self: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-textinput2 {
  color: var(--dl-color-gray-black);
  width: 465px;
  height: 45px;
  align-self: flex-start;
  border-width: 0px;
  background-color: var(--dl-color-primary-700);
}
.home-text092 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  align-self: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-textinput3 {
  color: var(--dl-color-gray-black);
  width: 465px;
  height: 45px;
  align-self: flex-start;
  border-width: 0px;
  background-color: var(--dl-color-primary-700);
}
.home-text095 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  align-self: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-textinput4 {
  color: var(--dl-color-gray-black);
  width: 465px;
  height: 146px;
  align-self: flex-start;
  border-width: 0px;
  background-color: var(--dl-color-primary-700);
}
.home-container123 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text169 {
  align-self: flex-start;
  text-align: left;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-navlink16 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: underline;
  padding-left: var(--dl-space-space-halfunit);
}
.home-button {
  background-color: #2fa36f;
  width: 210px;
  height: 45px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-width: 0px;
  border-radius: 8px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-gray-black);
}
.home-text096 {
  color: var(--dl-color-primary-700);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image12 {
  height: 2rem;
  object-fit: cover;
}
.home-navlink6 {
  text-decoration: none;
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link7 {
  display: contents;
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
@media (max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }
  .home-container04 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text041 {
    text-align: center;
  }
  .home-container08 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text003 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text004 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text005 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text006 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text007 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-dienstleistungen1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container07 {
    grid-template-columns: 1fr;
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text041 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container08 {
    grid-template-columns: 1fr 1fr;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media (max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    width: 479px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-container04 {
    width: 100%;
    height: 136px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero-heading {
    width: 479px;
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-hero-button1 {
    text-decoration: none;
  }
  .home-dienstleistungen1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-gallery {
    padding: var(--dl-space-space-unit);
  }
  .home-container08 {
    grid-template-columns: 1fr;
  }
  .home-form {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-image12 {
    margin-bottom: var(--dl-space-space-unit);
  }
}

/* nav bar styling  */
.home-top {
  margin: 0;
}

.home-nav {
  align-items: center;
  gap: 50px;
  height: 100%;
}

.home-links1 {
  align-self: center;
  align-items: center;
  gap: 30px;
}

.home-links1 > a {
  margin: 0;
}

/* destkop nav  */

.home-navbar-interactive {
  width: 100%;
}

.logoHeaderContainer {
  display: flex;
  align-items: center;
  gap: 48px;
}

@media screen and (max-width: 1100px) {
  .home-text {
    padding: 0;
  }

  .home-navbar-interactive {
    padding: 0;
    height: 96px;
  }

  .home-container01 {
    width: 96px;
    height: 96px;
  }

  .home-container02 {
    margin-right: 10px;
  }

  .home-image {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
  }

  .home-links {
    gap: 18px;
  }

  .home-links a {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .home-navbar-interactive {
    padding-right: 32px;
  }

  .nav-socials {
    margin-bottom: 32px;
  }

  .home-link5 {
    margin: 0;
  }

  .home-logo {
    width: 70px;
    height: 70px;
  }

  .home-navbar-interactive {
    height: 100%;
  }

  .home-header {
    height: 96px;
  }
}

@media screen and (max-width: 600px) {
  .home-logo {
    width: 48px;
    height: 48px;
  }

  .home-icon10 {
    margin: 0;
  }
}

@media screen and (max-width: 479px) {
  .home-icon10 {
    margin: 0;
  }

  .logoHeaderContainer {
    gap: 10px;
  }
}

@media screen and (max-width: 360px) {
  .home-navbar-interactive {
    padding: 0;
    padding-right: 12px;
    justify-content: space-between;
  }

  .home-container-01 {
    width: 60px;
    height: 60px;
  }

  .home-text {
    padding: 0;
  }

  .logoHeaderContainer span {
    font-size: 14px;
    white-space: nowrap;
  }
}
