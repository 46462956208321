.hausmeisterdienste-container4,
.hausmeisterdienste-container5 {
  padding: 2rem;
}

.hausmeisterdienste-container3 {
  height: 98px;
}

@media screen and (max-width: 360px) {
  .hausmeisterdienste-text08 {
    font-size: 28px;
  }
}
