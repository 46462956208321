.feature-card-text {
  text-align: start;
}

.home-container27 {
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.home-container27 .home-image20 {
  width: auto;
  height: 100%;
  object-fit: contain;
  max-height: 75px;
}

.home-container111 {
  justify-content: space-between;
  height: 100%;
  padding: 30px;
  align-items: center;
}

.home-image11,
.home-container112 {
  position: static;
  margin: 0;
}

.home-container113 {
  height: 100%;
}

.home-container115,
.home-container115 > div {
  height: 100%;
}

.home-ueber-uns {
  height: 100%;
}

.home-navbar-interactive {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .home-container111 .home-image11 {
    max-width: 170px;
  }

  .home-image10 {
    height: 170px;
  }

  .home-container111 {
    padding: 30px 0;
  }

  .home-form {
    padding: 24px;
  }

  .home-form input {
    width: 100%;
  }
}

@media screen and (max-width: 989px) {
  .home-container111 {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0;
  }

  .home-image10 {
    height: unset;
    width: 100%;
    aspect-ratio: 16/9;
  }

  .home-container112 {
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 0 30px;
  }

  .home-container110 {
    gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home-desktop-menu {
    display: none;
  }
  .home-container27 .home-image20 {
    margin: 0;
  }

  .home-container27 {
    gap: 20px;
  }

  .home-container28 {
    width: 100%;
  }

  .home-image09 {
    width: 90%;
    object-fit: contain;
  }

  .home-container08 {
    justify-items: center;
  }

  .home-container08 img {
    width: 150px;
    object-fit: contain;
  }

  .home-container115 {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 0;
  }

  .home-container115 > div {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .home-kontakt {
    flex-direction: column;
    align-items: center;
  }

  .home-kontakt > div {
    width: 100%;
  }

  .home-header {
    height: 96px;
  }

  .home-navbar-interactive {
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .home-container112 {
    padding: 0 10px;
  }

  .home-container115 > div {
    width: 100%;
    align-items: start;
    text-align: start;
  }
}

@media screen and (max-width: 479px) {
  .home-form {
    margin: 12px;
  }

  .home-ueber-uns {
    border-width: 25px;
  }
}

@media screen and (max-width: 350px) {
  .home-image {
    width: 100%;
    height: 100%;
  }

  .home-navbar-interactive {
    padding: 0;
    padding-right: 12px;
    justify-content: space-between;
  }

  .home-text {
    padding: 0;
  }

  .home-container-01 {
    width: 60px;
    height: 60px;
  }

  .home-container114 {
    height: 100%;
  }

  .home-container113,
  .home-container112 {
    padding: 0.5rem;
  }

  .home-ueber-uns {
    border: none;
  }
}
